.bg-blue-gradient {
    background: rgb(41,51,132);
    background: linear-gradient(45deg,rgba(78,169,223,1) 0%, rgba(41,51,132,1)  100%);
}
.bg-blue-gradient-2{
    background: linear-gradient(40deg,rgb(71,154,202)0%, rgb(53,79,127) 100%);
}
.bg-sienna-op{
    background: rgba(239,120,85,0.4);
}
.bg-marino-op{
    background: rgba(67,104,151,0.13);
}
.bg-ghost{
    background-color: $ghost;
}
.bg-light-gray{
    background-color: $light-gray;
}
.bg-light-white{
    background-color: $light-white;
}

.c-black{
    color: $black;
}
.c-half-baked{
    color: $half_baked;
}
.c-red{
    color: $red;
}
.c-white{
    color:  $white;
}
.c-cyan{
    color: $cyan;
}
.c-wedgewood{
    color: $wedgewood;
}
.c-dark-blue,.c-dark_blue,.code-01{
    color: $dark_blue;
}
.c-marino{
    color: $san_marino;
}
.c-mandy,.code-03{
    color: $mandy;
}
.c-elm, .code-02{
    color: $elm;
}
.c-sienna, .code-04{
    color: $sienna;
}
.c-ghost{
  color: $ghost;
}
.c-aspargus{
    color: $asparagus;
}
.c-olivine{
    color: $olivine;
}
.c-casablanca{
    color: $casa_blanca;
}
.text-undeline{
    text-decoration: underline;
}
.fs-lh{
    font-size: 13px !important;
    line-height: 1.5;
}
.fs-2-5{
    font-size: 2.5rem;
}
.fs-2{
    font-size: 2rem;
}
.fs-1-5{
    font-size: 1.5rem;
}

.fs-3r{
    font-size: 3rem;
}
.fs-3-5r{
    font-size: 2.5rem;
}
.fs-1r{
    font-size: 1.1rem;
}
.fs-12px{
    font-size: 12px;
}
.fs-13px{
    font-size: 13px;
}
.fs-14px{
    font-size: 14px;
}
.fs-icon-6r{
    font-size: 6rem;
}
.line-height-2{
    line-height: 2rem;
}
.w-1-5r{
    width: 1.5rem !important;
}
.w-10{
    width: 16%;
}
.w-15p{
    width: 15px !important;
}
.w-40p{
    width: 50px;
}
.w-130p{
    width: 130px;

}
.w-250{
    width: 250px;
}
.w-150{
    width: 10.5rem;
}


.w-25{
    width: 25% !important;
}
.w-30{
    width: 30%;
}
.w-35{
    width: 35%;
}


.w-40 {
    width: 40% !important; }
  
.w-45{
    width: 45%;
}
.w-50 {
    width: 50% !important; }

.w-47{
    width: 47%;
}
.w-60{
    width: 60%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80% !important;
}
.w-90{
    width: 90% !important;
}
.w-90px{
    width: 90px;
}
.w-94{
    width: 94%;
}
.h-100vh{
    height: 100vh;
}

.max-h-100{
    max-height: 100px;
}
.max-w-1200{
    max-width: 1250px;
}
.max-w-500{
    max-width: 500px;
}
.max-w-600{
    max-width: 600px;
}
.max-h-90{
    max-height: 90px;
}

.max-h-30{
    max-height: 30px;
}

.max-h-240{
    max-height: 240px;
}
.max-h-250{
    max-height: 250px;
    overflow-y: auto;
}
.max-h-70{
    max-height: 74vh;
    overflow: auto;
}
.max-h-600{
    max-height: 600px;
    overflow-y: auto;
}
.h-50p{
    height: 50px;
}
.h-150{
    height: 150px;
}
.h-200{
    height: 200px;
}
.h-350{
    height: 350px;
    max-height: 350px;
    overflow-y: auto;
}
.h-90p{
    height: 90px;
}
.height-130{
    height: 130px;
}
.h-190{
    min-height: 200px;
}
.h-120{
    height: 7rem;
}

.min-w-100{
    min-width: 100px;
}
.min-w-250{
    min-width: 250px;
}

.mr-15{
    margin-right: 15px;
}
.ml-15{
    margin-left: 15px;
}
.my-1{
    margin-top: 1px;
    margin-bottom: 1px;
}
.py-6p{
    padding: 6px;
}
.px-5p{
    padding-left: 5px;
    padding-right: 5px;
}
.opacity-65{
    opacity: 0.50;
}

.opacity-80{
    opacity: 0.80;
}
.border-bottom-soft {
    border-bottom: 1px solid #eef5f9 !important; }
  
.no-click {
        pointer-events: none;
}

.align-center{
    align-items: center;
}

@media screen  and(max-width:699px){
    .fs-1{
        font-size: .8rem;
    }

    .px-5p{
        padding-left: 5px;
        padding-right: 5px;
    }
    .min-w-0{
        display: none;
    }

    .min-w-350{
        min-width: 350px;
        
    }
    .max-w-530{
        min-width: '';
        max-width: '';
    }
    .fs-3{
        font-size: .8rem;
    }
    .imageEdit{
        right: 0;
    }
    .dot-3{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: inline-block;
    }
    .dot-2 {&.bg-white-mov{
        width: '';
        height: '';
        background-color: none !important;
    }}
    .bg-white-desk{
        background: $white;
    }
    .left-33 {
        left: 7.2rem;
    }
    .card-doctor-result{
        width: 20rem;
    }
    .profile-dot{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .w-60{
        width: 100%;
    }
    .sm-w-60{
        width: 60%;
    }
    .hidden-for-mobile{
        display: none;
    }
    .rounded-mobile-20{
        border-radius: 2rem;
    }

    .grid-4-2,
    .grid-3,
    .grid-4, 
    .grid-6,
    .grid-5
    {
        display: grid;
        margin-left: 0px;
        margin-right: 0px;
        grid-column-gap: 0px;
        grid-template-columns: repeat(1, 1fr);
    }
    .grid-5-2
    {
        display: grid;
        margin-left: 0px;
        margin-right: 0px;
        grid-column-gap: 0px;
        grid-template-columns: repeat(5, 1fr);
    }
    .flex-grow-1,.flex-grow-xs{
        width: 100%;
    }
    .grid-xs-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .h-350{
        min-height: 150px;
        height: auto;
    }
    .grid-4-4{
        display: grid;
        margin-left: 1rem;
        margin-right: 1rem;
        grid-column-gap: .5rem;
        grid-template-columns: repeat(4, 1fr);
    }
}
.hidden{
    display: none;
}
.box-shadow-none{
    box-shadow: none;
}
.row-gap-5{
    grid-row-gap: .5rem;
}


@media screen and(min-width:700px) and(max-width:799px){
    .min-w-0{
        display: none;
    }
    .min-w-300{
        min-width: 50px;
    }
    .min-w-350{
        min-width: 350px;
        
    }
    
    .max-w-350{
        max-width: 200px;
    }
    .px-5p{
        padding-left: 5px;
        padding-right: 5px;
    }
    .grid-4{
        display: grid;
        margin-left: 2rem;
        margin-right: 2rem;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }
    .grid-4-2{
        display: grid;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-4-3{
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-4-4{
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }
    .h-465{
        min-height: 465px;
    }
    .flex-md-nowrap{
        flex-wrap: nowrap !important;
        white-space: nowrap;
        overflow: hidden;
    }
    .p-label-x-5{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
@media screen and(min-width:800px) {//and   (max-width: 1019px){
    .h-465{
        min-height: 465px;
    }
    .px-5p{
        padding-left: 5px;
        padding-right: 5px;
    }

    .fs-1{
        font-size: 1rem;
    }
    .min-w-0{
        display: none;
    }
    .min-w-300{
        min-width: 200px;
        
    }
    .min-w-350{
        min-width: 500px;
        
    }
    .max-w-250{
        max-width: 200px;
    }
    .max-w-350{
        max-width: 270px;
    }
    .max-w-530{
        min-width: 768px;
        max-width: 2550px;
    }
    .bg-white-desk{
        background-color: none;
    }
    .fs-3{
        font-size: 1.5rem;
    }
    .min-h-690{
        min-height: 690px;
    }
    .left-33{
        left: 5.2rem;
    }
    .dot-3{
        width: 300px;
        height: 300px;
        border-radius: 50%;
        display: inline-block;
    }
    .bg-white-mov{
        background-color: $white;
    }
    .dot-2 {
        width: 270px;
        height: 270px;
        border-radius: 50%;
        display: inline-block;
    }
    .profile-dot{
        width: 250px;
        height: 250px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fs-3-5r{
        font-size: 3.5rem;
    }
    .hidden-for-web{
        display: none;
    }
    .flex-md-nowrap{
        flex-wrap: nowrap !important;
        white-space: nowrap;
    }

    .no-wrap{
        flex-wrap: nowrap;
    }
    .modal-custom{
        max-width: 600px ;
    }
    .grid-4{
        display: grid;
        margin-left: 2rem;
        margin-right: 2rem;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-4-2{
        display: grid;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-4-3{
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-4-4{
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-6{
        display: grid;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(6, 1fr);
    }
    .grid-5{
        display: grid;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(3, 1fr);
        div:nth-child(4n+0){
            grid-column: span 5;
        }
    }
    .grid-5-2{
        display: grid;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(5, 1fr);
        div:nth-child(6n+0){
            grid-column: span 5;
        }
    }
    .grid-5-2-3{
        display: grid;
        grid-column-gap: .5rem;
        grid-template-columns: repeat(5, 1fr);
        div:nth-child(6n+0){
            grid-column: span 5;
        }
    }
    .grid-6-2-3{
        display: grid;
        grid-column-gap: .5rem;
        grid-template-columns: repeat(6, 1fr);
        div:nth-child(7n+0){
            grid-column: span 6;
        }
    }
    .grid-3{
        display: grid;
        margin-left: 1rem;
        margin-right: 1rem;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(3, 1fr);
    }
    .grid-2{
        display: grid;
        grid-column-gap: .5rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen  and(min-width: 1020px) and   (max-width: 1280px) {
    .hidden-for-tablet{
        display: none !important;
    }    

    .px-5p{
        padding-left: 5px;
        padding-right: 5px;
    }

    .min-w-90{
        min-width: 90px; 
    }

    .min-w-350{
        min-width: 580px;
        
    }
    .min-w-300{
        min-width: 250px;
        
    }
    .max-w-225{
        max-width: 225px;
        
    }
    .max-w-350{
        max-width: 270px;
    }
    .max-w-250{
        max-width: 450px;
    }
    .min-w-0{
        min-width: 0px;
    }
    .min-w-250{
        min-width: 0px;
    }
    .profile-dot-medium{
        width: 70px !important;
        height: 70px !important;
    }
}

@media screen and(min-width: 1281px){
    .hidden-for-tablet{
        display: none !important;
    }    
    .px-5p{
        padding-left: 5px;
        padding-right: 5px;
    }

    .min-w-90{
        min-width: 90px; 
    }
    .min-w-350{
        min-width: 350px;
        
    }
    
    .max-w-350{
        max-width: 350px;
    }
    .max-w-250{
        width: 200px;
    }
    .profile-dot-medium{
        width: 70px !important;
        height: 70px !important;
    }
}

.rounded-top-2{
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.rounded-top-0-5{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.rounded-bottom-2{
    border-bottom-left-radius: 2rem 2rem;
    border-bottom-right-radius: 2rem 2rem;
}
.rounded-bottom-0-5{
    border-bottom-left-radius: 0.5rem 0.5rem;
    border-bottom-right-radius: 0.5rem 0.5rem;
}
.rounded-20{
    border-radius: 2rem;
}
.rounded-10{
    border-radius: 1rem;
}
.rounded-right-2{
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
}
.rounded-left-0-5{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.rounded-10,.modal-content{
    border-radius: .5rem;
}
.rounded-1{
  border-radius: .15rem;
}
.rounded-r-20{
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
}
.rounded-l-20{
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
}
.border-bottom-mandy{
    border-bottom: 1px solid $mandy;
}
.border-top-marino{
    border-top: 1px solid $san_marino;
}
.border-bottom-marino{
    border-bottom: 1px solid $san_marino;
}
.border-bottom-wedgewood{
    border-bottom: 2px solid $wedgewood;
}
.border-top-half-baked{
    border-top: 1px solid $half_baked;
}

.hidden{
    display: none;
}
.dot {
    height: 40px;
    width: 40px;
    background-color: #f2f4f8;
    border-radius: 50%;
    display: inline-block;
    border: none;
  }
input:-internal-autofill-selected {
 background-color: $zumthor;
 color: $wedgewood !important;
}
.form-control{
    background-color: $zumthor;
    color: $wedgewood !important;
}
input{
    background-color: $zumthor;
}
.css-yk16xz-control{
    background: $zumthor !important;
    border: 1px solid $zumthor !important;
    .css-1uccc91-singleValue{
        color: $wedgewood !important;
    }
    .css-tlfecz-indicatorContainer{
        color: $wedgewood !important;
    }
}
.css-yk16xz-control-grouped-right{
    background: $zumthor !important;
    border-left-width: 1px !important;
    border-left-style:dashed !important;
    border-left-color: $san_marino !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    // border: 1px solid $zumthor !important;
    .css-1uccc91-singleValue{
        color: $wedgewood !important;
    }
    .css-tlfecz-indicatorContainer{
        color: $wedgewood !important;
    }
}
//stepbar icons
._2Jtxm._35Ago ._2kL0S {
    background-color: $mandy !important;
}
._2Jtxm._2ZUAI ._2JvrO,.bg-olivine {
    background-color: $olivine !important ;
}
.cHQikR{
    height: 100px !important;
}
.bg-now{
    border: 1px solid $olivine;
    background: $light-gray;
}
.bg-cancel{
    background: rgba(77,78,85,.25) ;
    border: 1px solid $sienna;
    pointer-events: none;
    opacity: .7;

}
.bg-ok{
    border: 1px solid $light-gray;
    opacity: .70;
    pointer-events: none;

}

.bg-white-mask{
    background-color: rgba(255, 255, 255,.2);
}
.bg-lwhite{
    background-color: $light-white;
}
.bg-light-grey{
  background-color: $light-gray;
}
.bg-zumthor{
    background-color: $zumthor;
}
.bg-cyan{
    background-color: $cyan;
}
.bg-marino{
    background-color: $san_marino;
}
.bg-dark_blue{
    background-color: $dark_blue;
}
.bg-olivine{
    background-color: $olivine;
}
.bg-elm{
    background-color: $elm;
}
.bg-asparagus{
    background-color: $asparagus;
}
.bg-wedgewood{
    background:$wedgewood;
}
.bg-half_baked{
    background-color: $half_baked;
}
.bg-black{
    background-color: $black;
}
.bg-red{
    background: $red;
}
.bg-none{
    background:none;
}
.bg-sienna{
    background: $sienna;
}
.bg-mandy{
    background-color: $mandy;
}
.bg-c-blanca{
    background-color: $casa_blanca;
}
.bg-light-gray{
    background-color: #bdbdbe9a;
}
.bg-light-white{
    background-color: $light-white;
}
.bg-body{
    background-color: $body-bg;
}
.border-white{
    border: 1px solid rgb(159, 160, 165);

}
.border-wedgewood{
    border: 1px solid $wedgewood;
}
.border-mandy{
    border: 1px solid $mandy !important;
    &.border-select{
        box-shadow: 0 0 0 1px $mandy;
    }
}
.border-none{
    border: none;
}
.border-hover:hover{
    border: 1px solid $wedgewood;
}
.cursor-pointer{
    cursor: pointer;
}
.modal-title{
    font-weight: bold;
    color: $wedgewood;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
}
.custom-control-input:checked ~ .custom-control-label::before {
    background: $mandy;
    border: $mandy;
    // width: 50px;
}
.custom-switch .custom-control-label::after {
    background: $mandy;
}
.custom-switch .custom-control-label::before {
    border:1px solid $mandy ;
}

.check-days{
    .custom-control-label{
        padding-top: 3px;
    }
    .custom-control-label::before{
        width: 1.2rem;
        height: 1.2rem;
    }
    .custom-control-label::after{
        width: 1.2rem;
        height: 1.2rem;
    }
}

// input time without am-pm
.without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
  }
  input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
  }


/* Timeline */

.timeline {
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  padding: 10px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 5%;


  &::before{
    background-color: $mandy;
  }


  .event {
    position: relative;

    p{
        padding-left: 1.5rem;
        color: $white;
        font-size: .75rem;
    }

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before, &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -75px;
      color: $white;
      content: attr(data-date);
      text-align: right;
      font-size: .80rem;
      font-weight: normal;
    }

    &:after {
      box-shadow: 0 0 0 4px fade($wedgewood,100%);
      background: $mandy;
      border-radius: 50%;
      height: 15px;
      width: 15px;
      content: "";
      top: 5px;
      margin-left: -7px;
    }
  }
}
.mx-h-85p{
    height: 85px;
}
.mx-h-200p{
    height: 200px;
    margin-bottom: 10px;
}
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px $zumthor;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $cyan;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $cyan;
  }
  #carousel-metrics{
    .carousel-indicators{
        bottom: -50px;
    }
    .carousel-indicators li{
        width: 15px;
        height: 15px;
        background-color: $mandy;
        border-radius: 50%;
    }
  }
.consult-tabs{
    &.nav-link.active,
    &:active{
        background-color: $elm;
        color: $white;
        font-weight: bold;
        border-radius: 10px 10px 0 0 ;
    }
    &:hover{
        color: $white;
    }
  }

.call-view {
  width: 100%;
  height: 100%;
}
.call-view__tracks {
  width: 100%;
  height: 100%;
  display: flex;
}
.remote-track--container {
  position: relative;
  flex: 1;
}
.remote-view-container {
    height: 100%;
}
.input-status-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-bottom: 4px solid #FAFAFA;
  border-right: 4px solid #FAFAFA;
  border-radius: 0px 0px 16px 0px;
  overflow: hidden;

  .input-status {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF3346;

    &> i {
      font-size: 28px;
    }
  }
}

.call-view__tracks__local-track-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px;

  z-index: 1;

  .call-view__tracks__local-track {
    width: 20%;
    height: 20%;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background: #78909C;

    -webkit-box-shadow: 0px 0px 64px -16px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 0px 64px -16px rgba(0,0,0,0.6);
    box-shadow: 0px 0px 64px -16px rgba(0,0,0,0.6);

    video {
      width: 100%;
      height: 100%;
      z-index: 1;

      background: #4CAF50;
    }
  }
}
.call-view__controls-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 2;

  .call-view__controls {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 32px;

    /* Generated with https://larsenwork.com/easing-gradients */
    background: linear-gradient(
      to top,
      hsla(0, 0%, 0%, 0.25) 0%,
      hsla(0, 0%, 0%, 0.247) 8.1%,
      hsla(0, 0%, 0%, 0.238) 15.5%,
      hsla(0, 0%, 0%, 0.224) 22.5%,
      hsla(0, 0%, 0%, 0.206) 29%,
      hsla(0, 0%, 0%, 0.185) 35.3%,
      hsla(0, 0%, 0%, 0.162) 41.2%,
      hsla(0, 0%, 0%, 0.137) 47.1%,
      hsla(0, 0%, 0%, 0.113) 52.9%,
      hsla(0, 0%, 0%, 0.088) 58.8%,
      hsla(0, 0%, 0%, 0.065) 64.7%,
      hsla(0, 0%, 0%, 0.044) 71%,
      hsla(0, 0%, 0%, 0.026) 77.5%,
      hsla(0, 0%, 0%, 0.012) 84.5%,
      hsla(0, 0%, 0%, 0.003) 91.9%,
      hsla(0, 0%, 0%, 0) 100%
    );

    .call-view__controls__icon-btn {
        color: white;
      height: 64px;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      transform: scale(1);
      transition: transform 200ms cubic-bezier(0.0, 0.0, 0.2, 1);

      background-color: #FAFAFA;
      border-radius: 50%;

      pointer-events: all;
      cursor: pointer;

      &:hover {
        transform: scale(1.25);
      }

      &.leave {
        background-color: #FF3346;
      }

      &.join {
        background-color: #29dd56;
      }

      &> i {
        font-size: 32px;
      }
    }
  }
}

.hidden {
    display: none !important;
}

.call_timer {
    z-index: 2;
    position: absolute;
    color: white;
    font-weight: 300;
    right: 0;
    padding: 20px;
}

// New Appointment modal
.active-time{
  border: solid 1px $wedgewood;
  color: $white !important;
  background-color: $wedgewood;
}

.card-doctor-result {
    overflow: hidden;
    position: relative;
}

.arrow-right {
    background-color: $sienna;
    // box-shadow: 0 0 3px 2px rgb(0, 0, 0/50%);
    height: 140px;
    left: -51px;
    position: absolute;
    top: -89px;
    width: 120%;
    transform: rotate(-20deg);
    opacity: .2;
  }
  .arrow-left {
    // background-color: $asparagus;
    // box-shadow: 0 0 3px 2px rgb(0, 0, 0/50%);
    height: 81px;
    right: -1rem;
    position: absolute;
    top: -1rem;
    width: 115%;
    // transform: rotate(10deg);
    opacity: .2;
    left: -1rem;
  }

.z-2{
    z-index: 2;
    position: inherit;
}
.top-right{
    position: absolute;
    top: 0px;
    right: 0px
}
.position-inherit{
    position: inherit;
    z-index: 2;
}
.bottom-0{
    bottom: 0px;
}


.dot-60p{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.profile-dot-2{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-dot-small{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-dot-medium{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drop-shadow{
    filter: drop-shadow(2px 6px 10px #0000001f);
}
.table-date-picker {
    overflow: auto;
    max-width: 100%;
    max-height: 500px;
  th {
    position: sticky;
    left: 0;
    background: white;
  }
  td:hover,td:active{
      p{
      background: $mandy;
      color: $white;
      }
  }
}

.date-active{
    &.nav-link.active,
    &:active{
        border-radius: 20px 0;
        background-color: rgba(255,98,134,.2);
        border-color: rgba(255,98,134,.2);
        font-weight: bold;
    }
    padding: 10px !important;
}
.mh-tabs{
    &.nav-link.active,
    &:active{
        border-radius: 20px 20px 0 0;
        background-color: rgba(71,154,202,.4);
        border-color: rgba(71,154,202,.4);
        font-weight: bold;
    }
    padding: 10px !important;
}
.recipe {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;

    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 
    "Header"
    "Prescriptions"
    "Footer"
    ;

}

.recipe-prescriptions {
    grid-area: Prescriptions;
}
.recipe-footer {
    grid-area: Footer;
    background-color: $cyan;
                   width: 100%;
    // clip-path: ellipse(50% 100% at 50% 110%);
    color: white;                                                                                                                                 
}
.recipe-landscape {
    // min-width: 29.7cm; 
    // min-height: 21cm; 
    position: relative;
}
.recipe-portrait {
    // min-width: 21cm; 
    // min-height: 29.7cm; 
    position: relative;
}
.o-switch{
    background: $light-gray;
    input {
        visibility:hidden;
        margin-right: -20px;
    }
    label {
        cursor: pointer;
    }
    input:checked + label {
        background: $cyan;
        color: $white;
        padding: 5px;
    }
}

.homecmp-container .webcam-container
{
display: flex;
flex-direction: column;
text-align: center;
padding:10px 0;
}
.homecmp-container .webcam-container .webcam-btn
{
margin-top: 10px;
width:30%;
}

.imageEdit
{
    position: absolute;
    background-color: '#ccc';
    align-self: center;
    bottom: 0px;
    

}

.signImage
{
    height: 100px;
    pointer-events: none;
    align-self: left;
}

.signContainer
{
    align-content: center;
}
// .recipe-footer {
//     background-color: rgb(14, 123, 196);
//     width: 100%;
//     margin: 0;
//     // min-height: 100px;
//     clip-path: ellipse(50% 98% at 50% 110%);
//     position: absolute;
//     bottom: 0;
//     color: white;
// }
.wizard{
    width: 100%;
    display: inline-block;
}

.input-wrap{
    min-height: 20px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}
// calendar styles 
.calendar-root {
    --grid-gap: 2px;
    --text-color-light: rgba(0, 0, 0, 0.4);
    --text-color-primary: rgba(0, 0, 0, 0.7);
    --grid-background-color: rgb(211, 205, 198);
    --grid-foreground-color: white;
    --space-sm: 4px;
    --space-md: 8px;
    --space-lg: 16px;
    width: 100%;
  }
/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
    color: $white;
    background-color: $cyan;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: var(--space-md) 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .days-of-week,
  .days-grid {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--grid-gap);
    grid-row-gap: var(--grid-gap);
  }
  
  .days-grid {
    height: var(--grid-height);
    position: relative;
    border: var(--grid-gap) solid $wedgewood;
    background-color: $wedgewood;
  }
  
  .day-grid-item-container {
    position: relative;
    background-color: #f0f4f7;
    display: flex;
    flex-direction: column;
    
  }
  
  .day-grid-item-container:not(.current-month) .day-grid-item-header {
    color: var(--text-color-light);
  }
  
  /* Position the day label within the day cell */
  .day-grid-item-container .day-grid-item-header {
    
    padding: var(--space-md);
    padding-bottom: 0;
    flex-shrink: 0;
    font-weight: bold;
    
  }
  
  .day-grid-item-container > .day-content-wrapper {
    flex: 1;
    min-height: 0;
    position: relative;
    

  }
  
  .navigation-header {
    display: flex;
  }
  
  .navigation-header > * {
    margin-right: var(--space-md);
  }
  .tableWeek{
    
        table-layout: fixed;
        width: 100%;
  }
  .fixed-top-table { 
    position: sticky; 
    top: 0; 
    z-index: 1; 
    background-color: $san_marino;
    color: $white;
    font-size: 15px;
    th{
        padding: 1rem;
    }
}
.back-bar{
    color: #000!important;
    background-color: #e0ddddcc!important;
    border-radius: 10px;
    margin-bottom: 10px;
    min-height: 20px;
}
.ocupation-bar{
    color: #fff!important;
    border-radius: 10px;
}
.ocupation-80{
    position: absolute;
    z-index: 99;
    right: 0;
}
.z-index-99{
    z-index: 9;
}
.dropzone-preview{
    width: 100%;
    height: 50%;
    padding-top: 20%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    object-fit:contain;
}
.DatePicker_dateDayItem__XwRQy{
    width: 50px !important;
    height: 50px !important;
}
.highlight{
    background-color: rgba(71,154,202,0.8) !important;
    color: $white;
    // margin: 1px !important;
}
.react-calendar__tile{
    border: 1px solid $white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background: $wedgewood !important;
}
.copied-link{
    &.tooltip {
        position: relative;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;
      }
      
      .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
}
.react-tel-input {
    .form-control{
        width: 100%!important;
        border: none!important;
    }
    .flag-dropdown{
        border: none!important;
        background-color: $zumthor!important;
    }
}

.card-pricing{
    &:hover{
        box-shadow: -1px 8px 24px 16px rgba(116,167,100,0.44);
        -webkit-box-shadow: -1px 8px 24px 16px rgba(116,167,100,0.44);
        -moz-box-shadow: -1px 8px 24px 16px rgba(116,167,100,0.44);
    }
      .ribbon-2 {
        --f: 10px; /* control the folded part*/
        --r: 15px; /* control the ribbon shape */
        --t: 10px; /* the top offset */    
        position: absolute;
        inset: var(--t) calc(-1*var(--f)) auto auto;
        padding: 0 10px var(--f) calc(10px + var(--r));
        clip-path: 
          polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
        box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
      }
      p{
        line-height: 2rem;
      }
  
}
.card-active{
    background: linear-gradient(45deg,rgba(78,169,223,1) 0%, rgba(41,51,132,1)  100%);
    i,p,span,li,font,svg{
        color: $white !important;
    }
}

.card-number-in{
    letter-spacing: .5rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}