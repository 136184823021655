.font-bold {
  font-weight: $font-weight-bold;
}
.font-normal {
  font-weight: $font-weight-normal;
}
.font-light {
  font-weight: $font-weight-light;
}
.font-medium {
  font-weight: $font-weight-medium;
}
.primary-text {
  color: $primary;
}
.dark-blue-text {
  color: $dark_blue;
}
.cyan-text {
  color: $cyan;
}