.card-icon {
    position: relative;
    font-size: 3.5em;
    color: black;
}

.upload-icon {
    position: relative;
    background-color: $mandy;
    display: inline-block;
    border-radius: 50%;
    font-size: 1.7em;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
    color: white;
}